.award-nominees {
  padding-top: 80px;
  padding-bottom: 80px;
}

.latest-blogs__section {
  padding-top: 80px;
  padding-bottom: 40px;
}

.news-letter {
  padding-top: 80px;
  padding-bottom: 80px;
}

.news-letter-testimonials {
  padding-top: 40px;
  padding-bottom: 40px;
}

.testimonials-in-news-letter-content {
  padding-left: 16px;
}

.testimonial-name, .testimonial-position {
  color: #000 !important;
}

.award-nominees .slick-arrow:before {
  color: #000 !important;
}

.award-nominees .slick-prev {
  left: 25px !important;
  z-index: 1;
}

.award-nominees .slick-next {
  right: 25px !important;
  z-index: 1;
}

.news-letter-form {
  margin-top: 35px;
  max-width: 600px;
}

.news-letter-form button {
  align-self: flex-start;
  padding: 5px 25px;
  margin-top: 20px;
}

.news-letter-form .news-letter-input .MuiFilledInput-underline::before {
  content: unset;
}

.news-letter .review-container {
  position: relative;
  z-index: 1;
}

@media(max-width: 560px) {
  .testimonials-in-news-letter {
    flex-direction: column;
  }


  .testimonials-in-news-letter-content {
    padding-left: 0;
    padding-top: 16px;
  }
}
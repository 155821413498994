.icon_thankyou svg{
    color: #000;
    font-size: 25px;
    line-height: 30px;
}
.thanku_input .MuiInputBase-formControl{
    background-color: #fff !important;
    border-radius: 8px !important;
}
.thanku_input input{
    padding: 27px 20px 10px 30px;
}
.thanku_click_more span.MuiButton-startIcon{
    border: 2px solid #000;
    border-radius: 24px;
    padding: 1px;
}
.thanku_click_more span.MuiButton-startIcon svg{
    font-size: 16px;
    line-height: 26px;
    font-weight: bold;
}
.thanku_click_more a{
    text-transform: none;
    text-decoration: none;
}
.thanku_inline_cls{
    display: inline-block;
    padding-right: 5px;
}
.thanku_connect p{
    color: #fff;
    font-size: 18px;
    line-height: 28px;
    text-transform: lowercase;
    letter-spacing: 1px;
    margin-top: 0px;
    margin-bottom: 0px;
}
.thanku_main_connect{
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);
    margin: 0px auto;
    padding: 10px 20px;
    width: 800px;
    border-radius: 8px;
    margin-top: 25px;
    
}
.thanku_social_icon{
    vertical-align: middle;
}
.thanku_social_icon svg{
    font-size: 22px;
    margin-right: 5px;
    background-color: #fff;
    padding: 3px;
    border-radius: 24px;
}
.thanku_social_icon svg:last-child{
    margin-right: 0px;
}
.thanku_social_icon svg:first-child{
    margin-left: 0px;
}
.thanku_social_icon svg path{
    opacity: 0.7;
}
.thanku_subscribe p{
    color: #fff;
    font-size: 18px;
    line-height: 28px;
    text-transform: lowercase;
    letter-spacing: 1px;
    margin-top: 0px;
    margin-bottom: 0px;
}
.thanku_news_clss .icon_thankyou{
    margin-left: 15px;
    padding: 14px 13px;
    background-color: rgb(245, 200, 42);
    border-radius: 8px;
}
.thankYou{
    position: relative;
}
.thankYou .thanku_main_inner{
    background-image:url('./shape.png');
    height: 500px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.thanku_main_inner .thanku_inner_inner{
    position: absolute;
    margin: 0 auto;
    text-align: center;
    left: 0;
    right: 0;
    bottom: 60px;
}

@media (max-width: 1600px){
    .thankYou .thanku_main_inner{
        height: 460px;
    }
}

@media (max-width: 1400px){
    .thankYou .thanku_main_inner{
        height: 435px;
    }
}

@media (max-width: 1200px){
    .thankYou .thanku_main_inner{
        height: 400px;
    }
    .thankYou{
        margin: 50px 0px 0px !important;
    }
}

@media (max-width: 1025px){
    .thankYou .thanku_main_inner{
        height: 390px;
    }
    .thankYou{
        margin: 65px 0px 0px !important;
    }
}

@media(max-width: 991px){
    .thanku_main_connect{
        margin-right: 30px;
        margin-left: 30px;
        width: auto;
    }
    .thankYou .thanku_main_inner{
        height: 370px;
    }
    .thankYou{
        margin: 75px 0px 0px !important;
    }
}
@media (max-width: 815px){
    .thanku_inline_cls.thanku_news_clss{
        display: block;
        margin-top: 12px;
    }
    .thankYou{
        margin: 100px 0px 0px !important;
    }
}

@media (max-width: 580px){
    .thankYou {
        margin: 115px 0px 0px !important;
    }
}

@media (max-width: 480px){
    .thanku_text_cls p{
        padding: 0px 20px;
    }
    .thanku_text_cls p br{
        display: none;
    }
    .thanku_title_cls h1{
        font-size: 60px;
    }
    .thanku_main_connect{
        padding: 10px 8px;
    }
    .thankYou {
        margin: 125px 0px 0px !important;
    }
}

@media (max-width: 380px){
    .thanku_title_cls h1{
        font-size: 45px;
    }
    .thanku_news_clss .icon_thankyou{
        display: block;
    margin: 0 auto;
    text-align: center;
    }
    .thanku_news_clss .thanku_input{
        margin-bottom: 10px;
    }
    .thankYou {
        margin: 180px 0px 0px !important;
    }
}

.MuiIconButton-root:hover {
    background-color: rgb(245, 200, 42) !important;
}
.pagination_cls .MuiFlatPagination-root{
    margin: 0 auto !important;
    text-align: center;
    margin-bottom: 30px !important;
}
.pagination_cls .MuiFlatPagination-root button span{
    font-size: 26px;
    line-height: 36px;
    font-weight: 700;
}
.pagination_cls .MuiFlatPagination-root button{
    margin: 0px 7px;
}
.pagination_cls_up .MuiFlatPagination-root{
    margin: 0 auto !important;
    text-align: center;
    margin-top: 30px !important;
}
.pagination_cls_up .MuiFlatPagination-root button span{
    font-size: 26px;
    line-height: 36px;
    font-weight: 700;
}
.pagination_cls_up .MuiFlatPagination-root button{
    margin: 0px 7px;
}

@media (max-width: 380px){
    .pagination_cls .MuiFlatPagination-root button{
        margin: 0px 4px;
    } 
    .pagination_cls_up .MuiFlatPagination-root button{
        margin: 0px 4px;
    }  
}
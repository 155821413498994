.car_listing_main_bar .react-tag-input {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 55px;
    min-height: 55px;
    padding: 0;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 1rem;
    background: #fff;
    color: #333;
    border: none;
    border-bottom: 1px solid #000;
    border-radius: 0;
}

.car_listing_main_bar .react-tag-input .react-tag-input__input {
    padding: 0px;
}

.car_listing_main_bar {
    position: static;
    height: 43px;
    z-index: 99999;
    visibility: visible;
    overflow: visible;
    color: rgba(0, 0, 0, 0.87);
    cursor: text;
    position: relative;
    font-size: 1rem;
    box-sizing: border-box;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.1876em;
    height: 55px;
    padding: 0px;
    letter-spacing: 0.00938em;
}

.car_listing_main_bar .options-wrapperr {
    height: 200px;
    background-color: #fff;
    overflow: scroll;
    z-index: 99999999999;
    box-shadow: 0 2px 5px -1px rgb(0 0 0 / 60%);
    padding: 5px;
}

.car_listing_main_bar .options-wrapperr .options-boxx {
    background-color: #fff;
}

.car_listing_main_bar .options-wrapperr .options-boxx .cars-list:hover {
    background-color: #fff;
}

.car_listing_main_bar .options-wrapperr.d-none {
    z-index: 10000;
}

.car_listing_main_bar .car-filter__form-rows {
    z-index: -1;
}

.car_listing_main_bar .options-wrapperr .options-boxx input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.car_listing_main_bar .options-wrapperr .options-boxx .checkmark {
    position: absolute;
    top: 1px;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 3px;
}

.car_listing_main_bar .options-wrapperr .options-boxx .checkmark:hover {
    background: transparent;
}

.car_listing_main_bar .options-wrapperr .options-boxx input:checked~.checkmark {
    background-color: #f50057;
    border: none;
}

.car_listing_main_bar .options-wrapperr .options-boxx .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.car_listing_main_bar .options-wrapperr .options-boxx input:checked~.checkmark:after {
    display: block;
}

.car_listing_main_bar .options-wrapperr .options-boxx .checkmark:after {
    left: 5px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.car_listing_main_bar .options-wrapperr .options-boxx .checkbox {
    margin-left: 2px;
}

.car_listing_main_bar .options-wrapperr .options-boxx .checkbox label {
    position: relative;
    padding-left: 22px;
    font-size: 15px;
    line-height: 25px;
    vertical-align: top;
}

.hide_show_link:hover {
    text-decoration: none;
}

.car_listing_main_bar .list-wrapper svg path:hover {
    border-radius: 20px;
    background-color: #f5c82a;
}

.car_listing_main_bar .options-wrapperr .options-boxx .cars-list {
    padding: 0px !important;
}

.car_listing_main_bar .options-wrapperr::-webkit-scrollbar {
    display: none;
}

.car_listing_main_bar .options-wrapperr {
    scrollbar-width: none;
}

.car_listing_main_bar .options-wrapperr .options-boxx .sub_dropdown_menu {
    margin-left: 25px !important;
}
.brand-model-list {
  .MuiListItem-gutters {
    padding-top: 0;
    padding-bottom: 0;

    .MuiListItemText-root {
      margin: 0;
    }
  }

  ul {
    margin-bottom: 0;

    li.MuiListSubheader-sticky {
      line-height: 30px;
    }
  }
}
.pricing-area {
  overflow: hidden;
  margin-bottom: 15px;
  padding: 0 16px;
  display: flex;
}

.single-car-heading {
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
}

.detail-price {
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
  align-items: center;
  height: fit-content;
}

.heading-zone {
  padding: 0 0 30px;
}

.heading-zone h1 {
  color: #232323;
  font-size: 26px;
}

.short-history ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.short-history li {
  display: inline-block;
}

.short-history li a {
  color: #efba3a;
  text-decoration: none;
}

.single-price-tag {
  background-color: #F5C82A;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  color: #fff;
  font-size: 32px;
  font-weight: bold;
  height: 60px;
  padding: 0 30px;
  position: relative;
  text-indent: -6px;
  text-shadow: 1px 1px 1px #F5C82A;
  transform-origin: 88% 10% 0;
  display: flex;
  align-items: center;
}

.single-price-tag::before {
  border-bottom: 30px solid transparent;
  border-right: 30px solid #F5C82A;
  border-top: 30px solid transparent;
  content: "";
  height: 0;
  left: -29px;
  position: absolute;
  top: 0;
  width: 0;
}

.single-price-tag::after {
  background-color: #cecece;
  border-radius: 2.5px;
  box-shadow: -1px 1px 0 #8e8e8e inset;
  content: "";
  height: 5px;
  left: 88%;
  position: absolute;
  top: 10%;
  width: 5px;
}

.single-car-detail {
  display: flex;
  padding: 0 16px;
  margin-bottom: 16px;
  color: #777;
}

@media (max-width: 1023px) {
  .single-car-detail {
    flex-direction: column;
  }

  .detail-price {
    justify-content: flex-end;
  }

  .single-car-content {
    width: unset;
  }
}

@media (max-width: 768px) {
  .pricing-area {
    flex-direction: column;
  }
}

@media (max-width: 525px) {
  .single-price-tag {
    font-size: 24px;
    height: 40px;
    padding: 0 15px;
  }

  .single-price-tag::before {
    border-bottom: 20px solid transparent;
    border-top: 20px solid transparent;
  }
}
.profile-tab>.MuiBox-root {
  padding: 0 !important;
}

.profile-edit h2 {
  color: #323232;
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 0;
  text-transform: capitalize;
}

.user-stats {
  border-radius: 0;
  padding: 5px 15px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.user-stats > h2 {
  color: #fff;
  font-weight: 500;
  margin: 0;
  font-size: 24px;
}

.user-stats > small {
  color: rgba(255, 255, 255, 0.7);
  display: block;
  margin-top: 1px;
  text-transform: uppercase;
}

.user-stats:nth-child(1) {
  background: #34495e;
}

.user-stats:nth-child(2) {
  background: #3498db;
}

.user-stats:nth-child(3) {
  background: #1abc9c;
}

.profile-header {
  display: flex;
}

.profile-header__info {
  padding-left: 16px;
}

.inquiry-message-max-lines {
  display: block;/* or inline-block */
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
}

@media(max-width: 768px) {
  .profile-header {
    flex-direction: column;
  }

  .profile-header__info {
    padding-left: 0px;
    padding-top: 16px;
    text-align: center;
  }

  .profile-header__image {
    margin: 0 auto;
  }
}
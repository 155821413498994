.video_main iframe{
    width: 100%;
    height: 280px;
}
.video_main {
    height: 100%;
    padding: 20px;
}
.video_main h5{
    font-size: 21px;
    line-height: 24px;
    font-weight: 600;
    padding: 10px;
    margin-bottom: 0px;
    margin-top: 0px;
}
.video_main h5:hover{
    color: #e5cb52;
}
.video_main .video_main_inner {
    background-color: #fff;
    width: 100%;
    padding-right: 0px;
    box-shadow: 0px 6px 6px -3px rgba(0,0,0,0.2), 0px 10px 14px 1px rgba(0,0,0,0.14), 0px 4px 18px 3px rgba(0,0,0,0.12);
    border-radius: 4px;
}
.video_main .video_title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
.main_video_sec.MuiGrid-spacing-xs-1{
    width: calc(100% + -200px);
    margin: 0 auto;
}

@media (max-width: 1700px){
    .video_main iframe {
        width: 100%;
        height: 265px;
    }
}
@media (max-width: 1600px){
    .video_main iframe {
        width: 100%;
        height: 245px;
    }
}
@media (max-width: 1500px){
    .video_main iframe {
        width: 100%;
        height: 220px;
    }
}
@media (max-width: 1400px){
    .video_main iframe {
        width: 100%;
        height: 205px;
    }
}
@media (max-width: 1300px){
    .video_main iframe {
        width: 100%;
        height: 190px;
    }
    .main_video_sec.MuiGrid-spacing-xs-1 {
        width: calc(100% + -25px);
    }
}
@media (max-width: 1200px){
    .video_main iframe {
        width: 100%;
        height: 165px;
    }
}
@media (max-width: 1199px){
    .video_main iframe {
        width: 100%;
        height: auto;
    }
}
.key-features {
  margin-bottom: 15px;
  margin-top: 15px;
  display: flex;
}

.box-icon {
  background-color: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 0;
  /* height: 90px; */
  margin-top: 0;
  padding: 12px 7px 7px;
  text-align: center;
  flex: 1;
}

.box-icon:first-child, .box-icon:last-child {
  border: none;
}

.box-icon .petrol {
  color: #0083c9;
}

.box-icon .kilo-meter {
  color: #00a651;
}

.box-icon .reg-year {
  color: #e52d27;
}

.box-icon .body-type {
  color: #785edd;
}

.box-icon .car-color {
  color: #e4405f;
}

@media(max-width: 690px) {
  .box-icon {
    flex: 1 1 100px;
  }

  .box-icon span {
    font-size: 14px;
  }

  .box-icon img {
    width: 30px !important;
  }

  .box-icon .MuiSvgIcon-root {
    font-size: 30px !important;
  }

  .key-features {
    flex-wrap: wrap;
  }
}
.price-tag {
  position: absolute;
  bottom: 0;
  right: 0;
  line-height: 1.4;
  color: #000;
  font-size: 20px;
  font-weight: 600;
  padding: 5px 30px;
  text-transform: capitalize;
}

.car-title a {
  color: #232323;
  font-weight: 500;
  transition: all .25s ease-in-out;
  text-decoration: none;
}

.car-title a:hover, .car-title a:focus {
  color: #EFBA3A;
}

h3.car-title, h6.car-title {
  font-size: 18px;
  margin-bottom: 4px;
  margin-top: 0;
  text-transform: capitalize;
  line-height: 1.6em;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

/* .latest-listings .car-location {
  display: flex;
  align-items: center;
} */

.car-location.car-features {
  line-height: 30px;
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  /* flex-wrap: wrap; */
}

.car-location .car-feature {
  font-size: 12px;
  color: #1f1d22;
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.car-location .car-feature span {
  color: #777;
  white-space: nowrap;
}

.car-location .car-feature-icon {
  color: #a0a0a0;
}

.featured-ribbon {
  height: 100px;
  overflow: hidden;
  position: absolute;
  right: 0;
  text-align: right;
  text-transform: uppercase;
  top: 0;
  width: 100px;
  z-index: 1;
}

.featured-ribbon span::before {
  border-color: #5a3331 transparent transparent #5a3331;
  border-style: solid;
  border-width: 3px;
  content: "";
  left: 0;
  position: absolute;
  top: 100%;
  z-index: -1;
}

.featured-ribbon span::after {
  border-color: #5a3331 #5a3331 transparent transparent;
  border-style: solid;
  border-width: 3px;
  content: "";
  position: absolute;
  right: 0;
  top: 100%;
  z-index: -1;
}

.featured-ribbon span {
  box-shadow: 0 3px 12px -7px rgba(0, 0, 0, 1);
  color: #fff;
  display: block;
  font: 600 12px/30px "Source Sans Pro", sans-serif;
  position: absolute;
  right: -35px;
  text-align: center;
  text-transform: uppercase;
  top: 14px;
  transform: rotate(45deg);
  width: 125px;
}

.latest-featured-listings {
  background-color: #FCC602;
}
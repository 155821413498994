.well {
  background-color: #fff;
  border-radius: 2px;
  margin-bottom: 20px;
  display: flex;
}

.ad-listing .img-box {
  overflow: hidden;
  position: relative;
  width: 100%;
}

/* .ad-listing {
  max-height: 200px;
} */

.ad-listing .img-box__main {
  flex: 1 0 12%;
}

.ad-listing .img-box img {
  vertical-align: top;
  /* here */
  max-width: 100%;
  max-height: 300px;
}

.ad-listing .ad-status {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  left: 0;
  padding: 0 10px;
  position: absolute;
  text-transform: uppercase;
  top: 0;
  background-color: #efba3a;
}

.ad-listing .user-preview {
  position: absolute;
  right: 10px;
  top: 10px;
}

.ad-listing .content-area {
  overflow: hidden;
  position: relative;
  flex: 3 3 0%;
  display: flex;
  flex-wrap: wrap;
}

.car-listing__item {
  align-items: center;
  display: flex;
  padding-top: 5px;
}

.car-listing__item_content {
  padding-left: 10px;
}

.ad-listing .content-area h3 {
  color: #232323;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 4px;
  margin-top: 0;
  text-transform: capitalize;
  line-height: 1.6em;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: initial !important;
}

.ad-listing .total-images {
  color: #fff;
  line-height: 20px;
  font-size: 12px;
  text-align: center;
  background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
  bottom: 0;
  padding: 0 10px;
  left: 0;
  position: absolute;
}

.ad-meta-info {
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.ad-listing .content-area .ad-details {
  margin-top: 10px;
  overflow: hidden;
  position: relative;
}

.short-info {
  overflow: hidden;
}

.ad-listing .content-area .price {
  color: #efba3a;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 5px;
  overflow: hidden;
}

.ad-listing .content-area .btn {
  padding: 3px 10px;
}

.btn {
  font-size: 14px;
  letter-spacing: 1px;
  padding: 10px 15px;
  border-radius: 1px;
  transition: all .25s ease-in-out;
  font-weight: 600;
  font-family: 'Source Sans Pro', sans-serif;
  text-transform: uppercase;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.car__main-details {
  padding: 25px 0 25px 25px;
  flex: 1;
}

.car__pricing-other-details {
  padding: 25px;
  flex: 0 0 225px;
}

.ad-listing .content-area .ad-stats span {
  font-weight: 600;
  color: #232323;
}

.ad-listing .content-area .ad-stats {
  float: left;
  padding-right: 10px;
  padding-top: 0;
  width: 100%;
  color: #a0a0a0;
  font-size: 15px;
}

.ad-listing .user-preview {
  position: absolute;
  right: 10px;
  top: 10px;
}

@media(max-width: 850px) {
  .well {
    flex-direction: column;
  }
  .ad-listing .img-box__main {
    margin: 0 auto;
  }
}

@media(max-width: 768px) {
  .ad-listing .img-box img {
    max-height: unset !important;
  }
}

@media(max-width: 700px) {
  .ad-listing .content-area {
    display: block;
  }

  .ad-listing .content-area .car__main-details {
    padding-bottom: 0;
  }
}
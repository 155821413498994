.max-price__input input::-webkit-inner-spin-button,
.max-price__input input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.max-price__input .MuiFilledInput-underline::before,
.car-filter__form .news-letter-input .MuiFilledInput-underline::before {
    content: unset;
}

.car-filter__form-control {
    flex: 1;
    // margin: 8px 8px 8px 8px !important;
    min-width: 220px !important;
    align-self: flex-end;
    color: rgba(0, 0, 0, 0.54);
    // background-color: #F5C82A;
    padding: 5px !important;
    border-radius: 10px;
}

.car-filter__form-rows:last-of-type {
    align-items: flex-end !important;
}

.car-filter__form-rows {
    display: flex;
}

.car-filter__form-rows:not(:first-of-type) {
    margin-top: 16px;
}
.marginTop23{
    margin-top: 23px !important;
}
.marginTop5{
    margin-top: 5px !important;
}
 .select__control{
    height: 'auto' !important;
    border: 1px solid black !important;
    
    }
    .select__value-container{
        height: 65px ;
        overflow: auto !important;
    }
.car-filter__text-field {
    max-width: 200px !important;
}

.car-filter__form-control .MuiInputLabel-formControl.set-label-margin {
    margin-left: 20px;
}

.car-filter__main {
    padding: 40px;
    padding-right: 0;
}

// css by nayan
#cars-and-models {
    position: relative;
}

#tag-input-icon {
    position: absolute;
    pointer-events: none;
    right: 10px;
}

.d-flex {
    display: flex;
}

.ai-center {
    align-items: center;
}

.jc-sb {
    justify-content: space-between;
}

.jc-sa {
    justify-content: space-around;
}

.bgc-gray {
    background-color: #dddddd;
}

.inline-block {
    display: inline-block;
}

.cars-list {
    transition: .3s;
    cursor: pointer;
    padding: 0px 10px;
}

.cars-list:hover {
    background-color: #efbd0d;
}

.f-14 {
    font-size: 14px;
    color: #606264;
}

.fun-link:hover {
    text-decoration: underline;
    cursor: pointer;
}

small {
    color: #787c83;
}

.fw-500 {
    font-weight: 500;
}

.fun-link::before {
    content: "Show ";
}

.fun-link.show::before {
    content: "Hide ";
}

.list-wrapper {
    position: relative;
}

.options-box {
    transition: .2s;
    //position: absolute;
    z-index: 2;
    background-color: #fcc602;
    width: 100%;
    // display: none;
}

.checkbox {
    margin: 10px 0;
}

.d-none {
    display: none;
}

.form-control,
.react-tag-input__input {
    width: 100%;
    ;
    padding: 18px 15px;
    font-size: 16px;
}

// .list-wrapper:hover>.options-box {
//   display: block!important;
// }
.mytag {
    padding: 5px 8px;
    color: #000000;
    background-color: #dddddd;
    list-style-type: none;
    margin: 0 5px;
    font-style: 14px;
}

@media(max-width: 730px) {
    .car-filter__form-control {
        flex: 1 0 100%;
        max-width: 180px !important;
    }
    .car-filter-header {
        margin-right: auto;
    }
    .car-filter__form-rows:last-of-type {
        justify-content: center;
        align-items: initial !important;
    }
    .car-filter__form-inner-div {
        margin-left: 0;
    }
    .car-filter__main {
        padding: 30px;
    }
}

@media(max-width: 680px) {
    .car-filter-header {
        width: 100%;
    }
    .car-filter__form-control {
        flex: 1 0 100%;
        max-width: initial !important;
        width: 100% !important;
    }
    .car-filter__form-rows {
        flex-direction: column;
        margin-top: 0 !important;
        &>.car-filter__form-control {
            flex: 1;
        }
    }
    .marginTop23{
        margin-top: 21px !important;
    }
}

@media(max-width: 510px) {
    .car-filter__form {
        padding: 30px !important;
    }
    .car-filter__form-control {
        flex: 1;
        // max-width: 180px !important;
        min-width: 0 !important;
    }
    .car-filter__main {
        padding: 24px;
    }
    .tab-button {
        font-size: 12px !important;
    }
}

@media(max-width: 435px) {
    .tab-button {
        font-size: 10px !important;
    }
}

@media(max-width: 350px) {
    .car-filter__form {
        padding: 20px !important;
    }
    .car-filter__form-control {
        flex: 1;
        // max-width: 150px !important;
        min-width: 0 !important;
    }
    .car-filter__main {
        padding: 20px;
    }
    .car-filter-header {
        padding: 0 !important;
    }
}

.vehicle-model-sectn .vehicle_modal_inners input {
    padding: 9px 4px;
    font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    height: 1.1876em;
    margin: 0;
    display: block;
    padding: 6px 0 7px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
}

.vehicle-model-sectn .vehicle_modal_inners .vehicle_modal_dropdown {
    flex: 0 0 auto;
    color: rgba(0, 0, 0, 0.54);
    padding: 0 12px;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
    position: relative;
    transition: background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    background-color: rgba(0, 0, 0, 0.09);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.vehicle-model-sectn .vehicle_modal_inners .vehicle_modal_dropdown button {
    background: transparent;
    outline: none;
    border: none;
    color: #6a6a6a;
    position: absolute;
    top: calc(50% - 35px);
    right: 0;
}

// .vehicle-vehicle-inner{
//     background-color: #fff;
//     box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
//     border: transparent;
//     border-radius: 4px;
//     margin-top: 3px;
//     padding: 15px;
//     overflow: scroll;
//     height: 200px;
//     z-index: 99999999;
//     visibility: visible;
// }
// .vehicle-vehicle-inner ul{
//   padding-left: 0px;
// }
// .vehicle-btn {
//   position: absolute;
//   right: 0;
//   top: 15px;
//   vertical-align: middle;
//   padding: 0px 7px;
// }
// .main-vehicle-class{
//   position: relative;
//   display: inline;
// }
// .vehicle-vehicle-inner ul li{
//   padding: 12px 0px;
// }
// .vehicle-vehicle-inner ul li span{
//   vertical-align: top;
// }
// .vehicle-vehicle-inner ul li span.vehicle_modal_show{
//   float: right;
//   color: #ba8c1d;
// }
// .vehicle-vehicle-inner ul li input{
//   width: 16px;
//   height: 16px;
//   margin: 0 8px 0 0;
// }
// .main-vehicle-class ul.vehicel_sub_modal{
//   padding-left: 15px;
//   margin-bottom: 0px;
// }
// .main-vehicle-class ul.vehicel_sub_modal li{
//   padding: 6px 0px;
// }
// .outer_div_models{
//   background-color: red;
// }
// .main-vehicle-class{
//   display:inline-block !important;
// }
// .car-search-filter .car-filter__form-control{
//   width: 100%;
//   min-width: 100% !important;
// }
// .main-vehicle-class .e-multiselect{
//   background: #fff;
//   border: 1px solid black !important;
//   position: relative;
// }
// .main-vehicle-class .e-multi-select-wrapper{
//   transition: background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
//   background-color: rgba(0, 0, 0, 0.09);
//   padding-right: 65px !important;
//   padding-top: 4px !important;
//   padding-left: 8px !important;
// }
// .main-vehicle-class .e-multi-select-wrapper span.e-input-group-icon {
//   position: absolute;
//   right: 9px;
//   top: 9px;
// }
// .main-vehicle-class .e-multi-select-wrapper span.e-input-group-icon:hover {
//   position: absolute;
//   right: 9px;
//   top: 5px;
//   background-color: rgb(245, 200, 42) !important;
//   border-radius: 20px;
// }
// .main-vehicle-class .e-multi-select-wrapper span.e-input-group-icon::before{
//   vertical-align: middle;
//   font-size: 12px;
//   padding: 7px;
// }
// .main-vehicle-class .e-multi-select-wrapper:hover{
//   background-color: rgba(0, 0, 0, 0.13);
// }
// .main-vehicle-class .e-searcher input{
//   font-size: 16px;
// }
// .main-vehicle-class .e-multi-select-wrapper span input{
//   padding: 9px 4px;
// }
// .main-vehicle-class .e-multiselect.e-icon-anim .e-multi-select-wrapper {
//   padding-right: 65px !important;
//   padding-top: 19px !important;
//   padding-left: 8px !important;
// }
// .main-vehicle-class .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before, 
// .main-vehicle-class .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after, 
// .main-vehicle-class .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
// .main-vehicle-class .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,  
// .main-vehicle-class .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
// .main-vehicle-class .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,  
// .main-vehicle-class .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
// .main-vehicle-class .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after{
//   background: transparent;
// }
// .main-vehicle-class .e-input-group.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled):not(.e-float-icon-left){
//   border-color: transparent;
// }
// .main-vehicle-class .e-multiselect.e-icon-anim .e-searcher span.e-chips-close{
//   display: none;
// }
//Custom css

.car_listing_main .react-tag-input {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 65px;
    min-height: 65px;
    padding: 0;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 1rem;
    background: #fff;
    color: #333;
    border: 1px solid #000000;
    border-radius: 0;
}
.car-filter__form-rows .car-filter__form-control .MuiFilledInput-root{
    transition: none !important;
    background-color: #fff !important;
}
.car-filter__form-rows .car-filter__form-control .select__clear-indicator{
    display: none !important;
}

// .car-filter__form-rows .car-filter__form-control .MuiInputBase-root{
//     height: 65px !important;
//     overflow: auto !important;
// }
// .car-filter__form-rows .car-filter__form-control .MuiInputLabel-filled{
//     z-index: 0;
// }

// .car-filter__form-rows .car-filter__form-control .MuiInputLabel-root.Mui-focused{
//     color: #fff !important;
// }
.car_listing_main {
    position: static;
    height: 43px;
    z-index: 99999;
    visibility: visible;
    overflow: visible;
    color: rgba(0, 0, 0, 0.87);
    cursor: text;
    position: relative;
    font-size: 1rem;
    box-sizing: border-box;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.1876em;
    height: 55px;
    padding: 5px;
    letter-spacing: 0.00938em;
    margin-bottom: 10px;
}

.car_listing_main .options-wrapper {
    height: 200px;
    background-color: #fff;
    overflow: scroll;
    z-index: 99999999999;
}

.car_listing_main .options-wrapper::-webkit-scrollbar {
    display: none;
}

.car_listing_main .options-wrapper {
    scrollbar-width: none;
}

.car_listing_main .options-wrapper .options-box {
    background-color: #fff;
}

.car_listing_main .options-wrapper .options-box .cars-list:hover {
    background-color: #fff;
}

.car_listing_main .options-wrapper.d-none {
    z-index: 10000;
}

.car_listing_main .car-filter__form-rows {
    z-index: -1;
}

.car_listing_main .options-wrapper .options-box input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.car_listing_main .options-wrapper .options-box .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 3px;
}

.car_listing_main .options-wrapper .options-box .checkmark:hover {
    background: transparent;
}

.car_listing_main .options-wrapper .options-box input:checked~.checkmark {
    background-color: #f50057;
    border: none;
}

.car_listing_main .options-wrapper .options-box .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.car_listing_main .options-wrapper .options-box input:checked~.checkmark:after {
    display: block;
}

.car_listing_main .options-wrapper .options-box .checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

// .car_listing_main .options-wrapper .options-box  .checkbox{
//   margin-left: 10px;
// }
.car_listing_main .options-wrapper .options-box .checkbox label {
    position: relative;
    padding-left: 35px;
}

.hide_show_link:hover {
    text-decoration: none;
}

.car_listing_main .list-wrapper svg path:hover {
    border-radius: 20px;
    background-color: #f5c82a;
}

@media (max-width: 400px) {
    .car_listing_main .options-wrapper .options-box .checkbox label {
        padding-left: 25px;
        font-size: 13px;
    }
    .car_listing_main .options-wrapper .options-box .hide_show_link {
        font-size: 12px;
    }
}
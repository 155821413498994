.aws-btn {
  --slider-height-percentage: 50%;
  --slider-transition-duration: 306ms;
  --organic-arrow-thickness: 4px;
  --organic-arrow-border-radius: 0px;
  --organic-arrow-height: 40px;
  --organic-arrow-color: #bcd926;
  --control-button-width: 10%;
  --control-button-height: 25%;
  --control-button-background: transparent;
  --control-bullet-color: #ddff2d;
  --control-bullet-active-color: #bcd926;
  --loader-bar-color: #851515;
  --loader-bar-height: 6px;
}

.navigation-links {
  display: flex;
}

.navigation-links-menu-icon {
  display: none !important;
  color: #fff !important;
}

.avatar-profile-button {
  margin-right: 1rem;
}

.header-toolbar {
  background-color: #F5C82A !important;
  color: black;
  max-height: 50px !important;
  min-height: 0 !important;

  & .MuiDivider-vertical {
    height: 25px;
    background-color: #000;
    width: 2px;
  }
}

.header-toolbar-menu {
  align-items: initial !important;
}

@media (min-width: 600px) {
  .header-toolbar-menu {
    min-height: 70px !important;
  }
}

@media (max-width: 768px) {
  .navigation-links {
    display: none;
  }

  .navigation-links-menu-icon {
    display: block !important;
  }
}

@media(max-width: 625px) {
  .header-fullname {
    display: none;
  }
}

@media (max-width: 424px) {
  .avatar-profile-button {
    margin-right: 0;
  }
}

.swipe-drawer-main-menu .MuiDrawer-paperAnchorLeft {
  width: 80% !important;
}

.bgColor{
  background-color: rgba(0,0,0,0.8) !important;
}

.close_menu_icon {
  font-size: 36px !important;
  line-height: 46px;
  margin: 13px 15px;
}
.single-car-sidebar {
  flex: 0 0 33%;
  padding-right: 15px;
  padding-left: 15px;
}

.single-car-content {
  flex: 1;
  padding-right: 15px;
  padding-left: 15px;
  width: 500px;
}

@media (max-width: 768px) {
  /* .single-car-content, .single-car-sidebar {
    width: 100%;
    float: none;
  } */
}

.main_slider__main_div {
  cursor: pointer;
}

.slider-container {
  position: relative;
}

.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  z-index: 2;
  color: white; 
}

.prev-button {
  left: 0;
}

.next-button {
  right: 0;
}

.fullscreen-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.fullscreen-image {
  width: 90%;
  height: 90%;
  object-fit: contain;
}

.category-list-icon {
  background: #fff none repeat scroll 0 0;
  /* overflow: hidden; */
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.category-icon-green {
  background: #5cb85c;
  padding: 12px 20px;
}

.category-icon-purple {
  background: #785edd;
  padding: 12px 20px;
}
.category-icon-yellow {
  background: #f5c849;
  padding: 12px 20px;
}

.category-list-title {
  overflow: hidden;
  padding: 0 20px;
  position: relative;
  text-align: left;
}

.category-list-title h5 {
  margin: 0;
  color: #232323;
  display: block;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  text-decoration: none;
  cursor: pointer;
}

.category-list-title h5:hover {
  color: #efba3a;
}

#root {
  background: #f6f6f6;
}

.user-contact-info {
  background-color: #fff;
  margin-bottom: 5px;
}

.user-info-card {
  padding-bottom: 15px;
  padding-top: 25px;
  display: flex;
}

.user-photo {
  border-right: 1px dashed #e0e0e0;
}

img.img-circle {
  border-radius: 50%;
}

.user-photo img {
  height: 90px;
  width: 90px;
  object-fit: cover;
}

.user-information .UserName {
  font-size: 20px;
  font-weight: 600;
  color: #232323;
}

.item-date {
  font-size: 14px;
  margin-top: 10px;
  padding-bottom: 8px;
}

.item-date a {
  color: #efba3a;
  text-decoration: none;
}

.user-information, .user-photo {
  padding-right: 15px;
  padding-left: 15px;
}

.UserName a {
  text-decoration: none;
  color: #000;
}

.widget {
  overflow: hidden;
  position: relative;
  border-color: #eeeeee;
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 0;
  background-color: #fff;
  margin-top: 5px;
}

.widget-heading {
  background-color: #fff;
  border-color: #eeeeee;
  border-bottom-color: #eeeeee;
  border-bottom: 1px solid #eeeeee;
  border-radius: 0;
  color: #232323;
  padding: 0;
}

.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: bold;
  padding: 15px;
  text-decoration: none;
}

.recent-ads-list {
  border-top-color: #eeeeee;
  background-color: #fff;
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: flex-start;
}

a.recent-ads-list-image-inner img {
  height: 80px;
  width: 100px;
  object-fit: cover;
}

.recent-ads-list-content {
  padding-left: 16px;
}

.recent-ads-list-title {
  color: #232323;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 0;
}

.recent-ads-list-title a {
  color: #232323;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  text-decoration: none;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.recent-ads-list-location {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

.recent-ads-list-location li {
  padding: 10px 0;
}

.recent-ads-list-location li a {
  color: #a0a0a0;
  font-size: 14px;
  font-weight: normal;
  text-transform: uppercase;
  text-decoration: none;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.recent-ads-list-price {
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  color: #efba3a;
}

.widget-saftey {
  border-top-color: #eeeeee;
  background-color: #fff;
  padding: 20px 25px;
}

.widget-saftey ol {
  padding-left: 15px;
}

@media (max-width: 425px) {
  .recent-ads-list {
    flex-direction: column;
  }

  .recent-ads-container, .recent-ads-list-content {
    flex: 1;
  }

  .recent-ads-list-content {
    padding-left: 0px;
    padding-top: 10px;
  }

  .recent-ads-list-title a {
    font-size: 20px;
    text-transform: capitalize;
  }
}
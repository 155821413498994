.chats__main-div {
  display: flex;
  min-height: calc(100vh - 656px);
}

.chats__chat-users-list {
  flex: 0 0 300px;
  margin-right: 20px;
  color: #777;
}

.chats__chat-user-messages {
  flex: 1;
  max-height: 700px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.chat-active {
  text-decoration: none !important;
  background-color: rgba(0, 0, 0, 0.08) !important;
}

.chat-bubble {
  max-width: 70%;
}

@media (max-width: 1000px) {
  .chats__chat-users-list {
    flex: 0 0 250px;
  }
}

@media (max-width: 767px) {
  .chats__chat-users-list {
    flex: 1;
    margin-right: 0;
  }

  .chat-bubble {
    max-width: 85%;
  }
}
body {
    margin: 0;
    /* font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Lucida Grande, Helvetica, Arial, sans-serif !important; */
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

*,
*::before,
*::after {
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

.mx-1200 {
    max-width: 1200px;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}


/* .main-root-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
} */

.bg-primary {
    background-color: #FCC602 !important;
}

.bg-transparent {
    background: transparent;
    background-color: transparent !important;
}

.bg-black {
    background-color: #000 !important;
}

.text-gray {
    color: #848484 !important;
}

.text-dark {
    color: #000000 !important;
}

.font-weight-900 {
    font-weight: 900 !important;
}

.rounded-circle {
    border-radius: 50% !important;
}

.rounded-0 {
    border-radius: 0 !important;
}

.d-flex {
    display: flex !important;
}

.flex-column {
    flex-direction: column !important;
}

.justify-content-center {
    justify-content: center !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.align-items-center {
    align-items: center !important;
}

.align-items-end {
    align-items: flex-end !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.image-fade-in-effect {
    animation: fadeIn 1s;
}

.image-fade-out-effect {
    animation: fadeOut 1s;
}

.d-none-animation {
    display: none !important;
    animation: fadeOut 1s;
}

.d-block-animation {
    display: block !important;
    animation: fadeIn 1s;
}

.d-none {
    display: none !important;
}

.d-block {
    display: block !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.user-select-none {
    user-select: none !important;
}

.position-relative {
    position: relative !important;
}

.position-initial {
    position: initial !important;
}

.w-100 {
    width: 100% !important;
}

.h-100 {
    height: 100% !important;
}

.capitalize {
    text-transform: capitalize !important;
}

.breadcrumb-active {
    color: #EFBA3A !important;
}

.shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
    box-shadow: none !important;
}

.text-error {
    color: #f44336 !important;
}

.font-weight-normal {
    font-weight: normal !important;
}

.font-weight-bold {
    font-weight: bold !important;
}

.font-size-12 {
    font-size: 12px !important;
}

.font-size-14 {
    font-size: 14px !important;
}

.font-size-16 {
    font-size: 16px !important;
}

.font-size-18 {
    font-size: 18px !important;
}

.font-size-20 {
    font-size: 20px !important;
}

.object-fit-cover {
    object-fit: cover !important;
}

.MuiAutocomplete-popper {
    z-index: 11111111111 !important;
}

.bnTxEc::after {
    position: unset !important;
}

.drQBbj:after {
    position: unset !important;
}

.makeStyles-root-1 {
    background-color: rgba(0, 0, 0, 0.9) !important;
}

.jss1 {
    background-color: rgba(0, 0, 0, 0.9) !important;
}
.image-w-auto{
    margin: 0 auto;
    width: 40px; height: 40px; object-fit: contain;
}
.max-w-200{
    width: 50%;
    min-width: 250px;
}
.dealer-detail {
  display: flex;
}

.dealer-detail__sidebar {
  flex: 0 0 270px;
  margin: 0 auto;
  padding-left: 16px;
}

.dealer-detail__content {
  flex: 1;
}

@media (max-width: 1200px) {
  .dealer-detail__sidebar {
    flex: 0 0 250px;
  }
}

@media(max-width: 920px) {
  .dealer-detail {
    flex-direction: column;
  }

  .dealer-detail__content {
    margin-top: 16px;
  }

  .dealer-detail__sidebar {
    padding-left: 0;
    /* display: flex;
    align-items: center; */
  }

  .dealer-detail__sidebar_cards {
    display: flex;
    align-self: stretch;
  }

  .dealer-detail__sidebar_card {
    align-self: stretch;
    margin-top: 0;
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media(max-width: 630px) {
  .dealer-detail__sidebar_cards {
    display: block;
  }
}

@media(max-width: 526px) {
  .dealer-detail__sidebar {
    flex: 1;
    width: 100%;
  }
}
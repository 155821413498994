@media(max-width: 575px) {
    .insurance{
        padding: 80px 0px !important;
    }
    .insurance .insuramce-title{
        font-size: 30px;
    }
    .insurance .buttons{
        width: 100% !important;
    }
}
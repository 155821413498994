.testimonial-6 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 265px;
}

.testimonial-6 .testimonial-text {
  padding: 30px 15px 60px 15px;
  /* flex: 1; */
}

.testimonial-text {
  padding: 15px;
  position: relative;
}

.quote-left, .quote-right {
  position: relative;
}

.bg-white {
  background-color: #fff!important;
}

.bg-gray {
  background-color: #f6f6f6;
}

.testimonial-6 .quote-left:before {
  left: -10px;
}

.testimonial-6 .quote-left:before, .testimonial-6 .quote-right:after {
  color: #b5b5b5;
  font-size: 60px;
}

.quote-left:before {
  content: open-quote;
  left: 0;
}

.quote-left:before, .quote-right:after {
  /* font-family: FontAwesome; */
  position: absolute;
  z-index: 10;
  top: 0px;
}

.testimonial-6 .quote-right:after {
  right: 0px;
  bottom: 15px;
  top: auto;
}

.quote-right:after {
  content: close-quote;
  left: auto;
  right: 0;
}

.testimonial-6 .testimonial-detail {
  position: relative;
  padding: 10px 15px;
  top: -10px;
  width: 85%;
}

clearfix:after, .clearfix:before {
  content: "";
  display: table;
}

.testimonial-text .testimonial-text-p {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.testimonial-text p:last-child {
  margin: 0;
}

p {
  line-height: 26px;
  margin-bottom: 24px;
}

.text-white .title-small, .text-white h1, .text-white h2, .text-white h3, .text-white h4, .text-white h5, .text-white h6, .text-white p {
  color: #fff;
}

.testimonial-position {
  font-style: italic;
}

.text-white {
  color: #fff !important;
}

.testimonial-6 .testimonial-pic {
  position: absolute;
  right: 20px;
  top: -50px;
  z-index: 2;
}

.testimonial-pic.radius {
  border-radius: 100%;
}

.testimonial-pic {
  background: #fff;
  width: 80px;
  height: 80px;
  position: relative;
  display: inline-block;
  border: 5px solid #fff;
}

.testimonial-pic img {
  border-style: none;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

.testimonial-pic.radius img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  -webkit-border-radius: 100%;
}

.testimonial-6 .testimonial-detail:after {
  background-color: inherit;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  right: -23px;
  top: 0;
  -o-transform: skewX(35deg);
  -moz-transform: skewX(35deg);
  -webkit-transform: skewX(35deg);
  transform: skewX(35deg);
  width: 50px;
  z-index: 1;
}

.clearfix:after {
  clear: both;
}

.section-head {
  padding: 0 16px;
}

.section-head h2.newsletter-title {
  font-size: 30px;
  margin-bottom: 10px;
  line-height: 30px;
}

.section-head h1.section-title {
  font-size: 35px;
  margin-bottom: 10px;
  line-height: 30px;
  margin-top: 0;
  padding: 10px 20px;
  color: #000;
  width: fit-content;
  font-weight: 900;
  box-shadow: none;
}

.section-head h2.section-title {
  font-size: 30px;
  margin-bottom: 10px;
  line-height: 30px;
  margin-top: 0;
  padding: 10px 20px;
  color: #000;
  width: fit-content;
  font-weight: 900;
  box-shadow: none;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-primary {
  color: #FCC602 !important;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-center.section-head p {
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
}

.section-head p {
  padding-top: 10px;
  font-size: 16px;
  color: #848484;
}
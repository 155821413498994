.car-results {
  display: flex;
}

.car-results__filter {
  flex: 0 0 320px;
}
.FBbQH.MuiIconButton-colorPrimary:hover {
  color: #fff;
}
.car-results__listing {
  flex: 1;
}

.car-results__filter-header {
  padding: 16px;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.car-results__filter-header-name {
  flex: 1;
}

.car-results-filter_button {
  display: none;
}

.car-results__filter-responsive {
  position: fixed;
  top: 0;
  height: 100vh;
  z-index: 11111;
  background: #000000b3;
  left: 0;
  right: 0;
  overflow: hidden;
  padding: 0 !important;
}

.car-results__filter-responsive form {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.car-results__filter-responsive .car-results__filter-header {
  background-color: #fff;
}

.car-results__filter-header-icon {
  display: none !important;
}

@media (max-width: 1200px) {
  .car-results__filter {
    flex: 0 0 270px;
  }
}

@media (max-width: 1024px) {
  .car-results__filter {
    display: none;
  }

  .car-results-filter_button {
    display: block;
  }

  .car-results__filter-header-name {
    padding-left: 10px;
  }

  .car-results__filter-header-icon {
    display: block !important;
  }
}
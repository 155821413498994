.content-box-grid {
  border: 1px solid #e7e7e7;
  border-radius: 1px;
  background: #fff;
  padding: 20px 30px;
  margin-bottom: 15px;
}

.DetailRow {
  display: flex;
  justify-content: space-between;
}

.DetailRow__description {
  text-overflow: ellipsis;
  word-break: break-word;
}

.DetailRow .DetailRowChild {
  padding: 10px 0px;
  flex: 1;
}

.clear-both {
  clear: both;
}

.CarFeatureList {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.CarFeatureList li {
  list-style: outside none none;
  margin: 0;
  padding-bottom: 10px;
  flex: 0 0 33.33%;
  font-size: 17px;
}

@media (max-width: 767px) {
  .DetailRow {
    flex-direction: column;
  }

  .DetailRow .DetailRowChild {
    padding: 0 0 10px 0;
  }
}
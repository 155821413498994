.ContactSellerDialog {
  color: #232323;
  display: block;
  font-size: 20px !important;
  font-weight: 600;
  text-transform: capitalize !important;
  text-decoration: none;
  background: none !important;
  padding: 0 !important;
  letter-spacing: 0 !important;
  font-family: inherit !important;
}

.ContactSellerDialog:hover, .ContactSellerDialog:focus {
  color: #efba3a;
}

.ContactSellerSubmit {
  color: #ffffff !important;
  background-color: #efba3a !important;
  border-color: #efba3a !important;
  display: block !important;
  width: 100%;
  margin: auto !important;
}

.RecentAdsName {
  color: #efba3a;
  text-decoration: none;
}

.RecentAdsMail {
  color: #777;
}

.DialogTextBox {
  font: inherit;
  color: currentColor;
  width: 100%;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: MuiInputBase-keyframes-auto-fill-cancel;
  -webkit-tap-highlight-color: transparent;
}

.DialogTextareaBox {
  height: 100px;
}

.delearContactCard {
  background-color: #f5f5f5 !important;
}

.contact-input {
  background-color: #fff !important;
}
.country-code-select-border{
  border-color: #232323 !important;
  border: 1px solid #232323 !important;
}

.contact-delear-card {
  margin-bottom: 5px;
}
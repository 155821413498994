.about-us {
  display: flex;
  align-items: center;
}

.about-us__content, .about-us__image {
  flex: 1;
}

.about-key-features {
  display: flex;
  position: relative;
  bottom: -4px;
}

.about-key-features__feature {
  display: flex;
  padding: 10px 0;
}

.about-key-features__column-1, .about-key-features__column-2, .about-key-features__column-3 {
  flex: 1;
}

.about-key-features__column-1 .key-feature__icon {
  margin-left: 16px !important;
}

.about-key-features__column-3 .key-feature__icon {
  margin-right: 16px !important;
}

.about-us__statistics {
  text-align: center;
}

.about-us__car-brands {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.about-us__car-brands .sigle-clients-brand {
  flex: 1 1 20%;
  padding: 16px;
  opacity: 0.5;
  transition: all 0.3s ease-in;
  text-align: center;
}

.about-us__car-brands .sigle-clients-brand:hover {
  opacity: 1;
}

@media(max-width: 1200px) {
  .about-key-features {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .about-key-features__column-1, .about-key-features__column-3 {
    flex: 0 0 48%;
  }

  .about-key-features__column-2 {
    flex: 0 0 100%;
    order: 3;
    text-align: center;
    margin-top: 20px;
  }

  .about-key-features__column-2 img {
    margin: 0 auto;
  }
}

@media(max-width: 900px) {
  .about-us {
    flex-direction: column;
  }

  .about-us__image img {
    padding: 24px;
  }
}

@media(max-width: 700px) {
  .about-us {
    flex-direction: column;
  }

  .about-us__image img {
    padding: 24px;
  }

  .about-key-features__column-3 .about-key-features__feature {
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .about-key-features__column-3 .key-feature__icon {
    margin-right: 0 !important;
    margin-left: 16px;
  }

  .about-key-features__feature {
    justify-content: space-between;
  }

  .about-key-features__column-1, .about-key-features__column-3 {
    flex: 0 0 100%;
  }
}

@media(max-width: 590px) {
  .about-us__features-icon-description {
    display: flex;
  }

  .about-us__features-icon {
    margin-right: 16px;
  }
}
.faq__main-div {
  display: flex;
  flex-wrap: wrap;
}

.faq__form-block {
  flex: 1;
  padding: 8px;
}

.faq__form-block p {
  margin-top: 0;
  margin-bottom: 14px;
}

.faq__form-block ol li {
  padding: 10px 0;
}

.faq__sidebar {
  flex: 0 0 300px;
  margin-left: 20px;
  padding: 24px;
  color: #777;
}

.faq__title-block {
  flex: 0 0 100%;
  margin-bottom: 20px;
}

@media(max-width: 900px) {
  .faq__main-div {
    flex-direction: column;
  }

  .faq__sidebar {
    margin-left: 0;
    margin-top: 20px;
  }
}

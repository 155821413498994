.offer-slider .slick-current+.slick-slide .offer-slider__img {
  width: 100%;
  height: 240px;
  padding-top: 0;
}


.offer-slider {
  text-align: center;
  max-width: 1470px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
  margin-top: 30px;
}

.offer-slider__img {
  width: 100%;
  height: 240px;
  display: inline-block;
  transition: all .3s;
}

.offer-slider__img img {
  display: inline-block;
  object-fit: contain;
  font-family: 'object-fit: contain;';
}

.offer-slider__content-inside {
  background-color: #000000bf;
  height: 55px;
  margin-top: -55px;
  position: relative;
}

.latest-offers .slick-slide img {
  display: block;
}

.img-scale {
  object-fit: cover;
  font-family: 'object-fit: contain;';
  width: 100%;
}

.offer-slider__main {
  width: 100%;
  opacity: 0;
  transition: all .3s;
  max-width: 310px;
  display: inline-block;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.slick-center .offer-slider__main {
  opacity: 1;
}

.offer-slider__title {
  color: #222222;
  font-size: 24px;
  font-weight: 700;
}

.offer-slider__price {
  font-size: 16px;
  margin-bottom: 20px;
}

.offer-slider__list {
  font-size: 14px;
  padding-top: 20px;
  border-top: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -10px;
  margin-right: -10px;
}

.list-unstyled {
  padding: 10px 0 0;
  list-style: none;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

.offer-slider__desrip:not(:last-child):after {
  content: '';
  border-radius: 50%;
  background-color: #222;
  display: inline-block;
  vertical-align: middle;
  width: 5px;
  height: 5px;
  margin-left: 6px;
  margin-right: 6px;
}

.offer-slider__desrip {
  color: #222222;
}

@media(min-width: 1441px) {
  .offer-slider__content-inside {
    bottom: 9px;
  }
}

@media(max-width: 1440px) {
  .offer-slider__content-inside {
    bottom: 10px;
  }
}

@media(max-width: 1024px) {
  .offer-slider__content-inside {
    bottom: 0px;
  }
}

@media(max-width: 768px) {
  .offer-slider__content-inside {
    bottom: 10px;
  }
}

@media(max-width: 425px) {
  .offer-slider__content-inside {
    bottom: 5px;
  }
}

@media(max-width: 375px) {
  .offer-slider__content-inside {
    bottom: 0px;
  }
}

@media (max-width: 370px) {
  .offer-slider__list {
    font-size: 12px;
    margin-left: -12px;
    margin-right: -12px;
  }
}
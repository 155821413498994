.grid-list-post .loop-short {
  min-height: 300px;
}

article {
  /* margin: 16px; */
  padding: 0;
  border: 0;
  outline: 0;
  font: inherit;
  font-size: 100%;
  background: transparent no-repeat top left;
  display: block;
}

article div {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent no-repeat top left;
}

article.post, div.post {
  margin: 16px;
}

.post .thumb.container-img {
  position: relative;
  overflow: hidden;
}

.loop-short .container-img {
  max-height: 285px;
  overflow: hidden;
  display: block;
}

article a, .trans-color {
  transition: color 0.3s ease-in-out;
}

/* .img-responsive {
  display: block;
  width: 100% \9;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
} */

.img-responsive {
  display: block;
  width: 100% \9;
  max-width: 100%;
  height: auto;
}

.blog-img{
  object-fit: cover !important;
}

.loop-short:hover .container-text-loop:before {
  opacity: 1;
}

.loop-short .container-text-loop:before {
  position: absolute;
  z-index: 1;
  content: '';
  top: 0;
  left: 0;
  box-shadow: 0 0 13px 5px rgba(188, 188, 188, 0.6);
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.loop-short .container-img img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.loop-short:hover .container-text-loop .shadow-box {
  opacity: 0;
}

.loop-short .container-text-loop .shadow-box {
  position: absolute;
  top: 0;
  opacity: 1;
  height: 17px;
  width: 100%;
  z-index: 1;
  box-shadow: 0 0 13px 5px rgba(188, 188, 188, 0.3);
  transition: opacity 0.5s ease-in-out;
}

.loop-short .container-text-loop .content-txt-loop {
  padding: 25px;
  position: relative;
  z-index: 2;
  background-color: #fff;
  min-height: 170px;
  max-height: 230px;
}

.loop-short .post-categories {
  line-height: 10px;
  position: absolute;
  top: -18px;
  left: 0;
  right: 0;
}

.loop-short .post-categories a {
  background-color: rgba(177, 202, 100, 0.85);
  padding: 10px 14px 8px;
  font-size: 12px;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 1px;
  display: inline-block;
  vertical-align: middle;
  display: inline;
  zoom: 1;
  text-decoration: none;
  font-weight: bold;
}

.post-categories a {
  background-color: rgba(177, 202, 100, 0.85);
  border: 1px solid #b1ca64;
  padding: 10px 14px 8px;
  font-size: 10px;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 1px;
  display: inline-block;
  vertical-align: middle;
}

.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  user-select: none;
  vertical-align: middle;
  z-index: 1;
  will-change: opacity, transform;
  transition: all 0.3s ease-out;
}

.loop-short .title-article {
  margin-bottom: 10px;
}

.title-color-hover-design:hover .title-article a, .color-a-hover-design:hover span, .color-a-hover-design a:hover, .color-hover-design:hover {
  color: rgb(229, 203, 82) !important;
}

.loop-short .title-article a {
  color: #0c0c17;
  font-size: 24px;
  line-height: 28px;
  -webkit-font-smoothing: antialiased;
  text-decoration: none;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.loop-short .container-text-loop {
  margin: -10px 10px 0;
  background-color: #fff;
  position: relative;
  z-index: 2;
  text-align: center;
}

.loop-short .loop-excerpt {
  font-size: 14px;
  line-height: 24px;
  color: #50505e;
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.post .thumb.container-img .hover-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: none;
}

.visible-xs, .visible-sm, .visible-md, .visible-lg {
  display: none !important;
}

.loop-short .post-about {
  color: #aaaab1;
  font-size: 13px;
}

.loop-short .number-share {
  color: #757581;
  font-size: 13px;
}

.loop-short .post-about .separator {
  color: #d7d7da;
  margin: 0 3px;
}

.loop-short .number-share .icon-share {
  font-size: 12px;
  margin-right: 2px;
}

.blog__content p {
  margin-top: 0;
}

.blog__content #mui-rte-toolbar {
  display: none;
}

.privacy-policy__content p {
  margin-top: 0;
}

.blog__content-ol li, .privacy-policy__content ol li {
  padding: 10px 0;
}

.post-read-more-button {
  border-radius: 20px !important;
  border: 1px solid #000 !important;
  font-weight: 900 !important;
  margin-right: 16px !important;
  background-color: #F5C82A !important;
}

.custom-read-more-button {
  border-radius: 20px !important;
  border: 1px solid #000 !important;
  font-weight: 600 !important;
  /* margin-right: 16px !important; */
  margin: 0 auto;
}

@media (min-width: 1380px) {
  .grid-list-post .loop-short {
    min-height: 265px;
  }
  .hidden-lg {
    display: none !important;
  }
  .visible-lg {
    display: block !important;
  }
  .loop-short .title-article a {
    font-size: 21px;
    line-height: 23px;
  }
  .latest-blog_style .slick-prev {
    top: 30% !important;
  }
  .latest-blog_style .slick-next {
    top: 30% !important;
  }
}

@media (max-width: 1380px) and (min-width: 1020px) {
  .loop-short .container-img {
    height: 207px;
  }
  .latest-blog_style .slick-prev {
    top: 30% !important;
  }
  .latest-blog_style .slick-next {
    top: 30% !important;
  }
}
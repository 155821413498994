.services__main {
  display: flex;
}

.services__main-content, .services__main-image {
  flex: 0 0 50%;
}

.services__main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.services__main-content-secure-car {
  display: block !important;
  overflow-y: auto;
  max-height: 580px;
}

.services__main-content-secure-car::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

.services__main-content-secure-car::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

.services__main-content-secure-car::-webkit-scrollbar-thumb {
  background-color: #000;
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent)
}

.car-tracking__main, .car-tracking__main_initial {
  text-align: center;
  align-self: flex-start;
}

.car-tracking__main {
  margin-right: 30px;
}

.car-tracking__main_initial {
  margin-left: 30px;
}

.car-tracking__title {
  background-color: #F5C82A;
  padding: 10px;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 18px;
}

.car-tracking__price {
  padding: 5px;
  border: 5px solid #F5C82A;
  font-weight: 900;
  font-size: 34px;
}

.car-tracking__main_initial {
  border: 5px solid #F5C82A;
}

.car-tracking__price_initial {
  border: 0 !important;
}

.car-tracking__extra_price {
  padding: 10px;
  font-weight: 900;
  font-size: 24px;
}

.car-tracking__add_icon {
  right: -17px;
  position: absolute;
  top: 58px;
  font-size: 35px !important;
  font-weight: 900;
}

.car-tracking__or {
  font-weight: 900;
  font-size: 24px;
}

.car-tracking__extra_price_info {
  font-size: 10px;
  padding-left: 10px;
}

@media(max-width: 768px) {
  .services__main-content-title {
    font-size: 1.55rem !important;
  }

  .services__main-content-content {
    font-size: 0.875rem !important;
  }
}

@media(max-width: 650px) {
  .car-tracking-buttons {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .car-tracking__main, .car-tracking__main_initial {
    margin: 0;
    align-self: auto;
  }
}

@media(max-width: 610px) {
  .services__main {
    flex-direction: column;
  }

  .services__main-alter {
    flex-direction: column-reverse !important;
  }
}

@media(max-width: 400px) {
  .car-tracking__title {
    font-size: 14px;
  }
  .car-tracking__price {
    font-size: 26px;
  }
  .car-tracking__add_icon {
    right: -13px;
    top: 50px;
    font-size: 29px !important;
  }
  .car-tracking__extra_price {
    font-size: 18px;
  }
}

@media(max-width: 350px) {
  .car-tracking__add_icon {
    top: 70px;
  }
}
.post-ad__main-div {
  display: flex;
}

.post-ad__form-block {
  flex: 1;
  padding: 24px;
}

.post-ad__sidebar {
  flex: 0 0 300px;
  margin-left: 20px;
  padding: 24px;
  color: #777;
}
.aiModal{
  display:flex;
  justify-content:center;
flex-direction:column;
align-items:center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  background-color: #fff;
  border: 2px solid #000;
  box-shadow: 24;
  height:500px;
  padding-left: 2.5%;
  padding-right: 2.5%;
}
.aiMessageParent{height:380px;width:100%;overflow-y:auto; display: flex;
  align-items: center;}

.post-ad__multiple-fields {
  display: flex;
  margin-top: 20px;
}

.post-ad__multiple-fields > div {
  flex: 1;
}

.post-ad__multiple-fields > div:nth-child(2) {
  margin-left: 30px;
}

.car-feature-checkbox {
  flex: 0 0 30%;
}

.delete-image-button {
  position: absolute !important;
  top: -25px;
  right: -25px;
  color: #fff !important;
  background-color: rgba(0, 0, 0, 0.48) !important;
}

.delete-image-button:hover {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.delete-images {
  display: flex;
  flex-wrap: wrap;
}

.delete-image-div {
  margin: 20px;
}

@media(max-width: 1120px) {
  .car-feature-checkbox {
    flex: 0 0 49%;
  }
}

@media(max-width: 900px) {
  .post-ad__main-div {
    flex-direction: column-reverse;
  }

  .post-ad__form-block {
    margin-top: 20px;
  }

  .post-ad__sidebar {
    margin-left: 0;
  }

  .car-feature-checkbox {
    flex: 0 0 30%;
  }
}

@media(max-width: 820px) {
  .car-feature-checkbox {
    flex: 0 0 48%;
  }
}

@media(max-width: 600px) {
  .car-feature-checkbox {
    flex: 0 0 100%;
  }

  .post-ad__multiple-fields {
    flex-direction: column;
  }
.aiModal{
  width:500px
}
  .post-ad__multiple-fields > div:nth-child(2) {
    margin-left: 0;
    margin-top: 20px !important;
  }
}
@media(max-width: 520px) {
  .aiModal{
    width:400px
  }
}
@media(max-width: 420px) {
  .aiModal{
    width:340px
  }
}
.car-services .services__main {
  position: relative;
  z-index: 1;
  width: 100%;
}

.car-services .services__subtitle {
  font-size: 23px;
  line-height: 1;
}

.car-services .services__title {
  font-size: 30px;
  font-weight: 700;
  line-height: 1;
}

.car-services .services__slogan {
  margin-top: 20px;
  font-size: 18px;
  letter-spacing: 9px;
  text-align: left;
}

@media (min-width: 1024px) {
  .car-services .services__subtitle {
    font-size: 30px;
  }

  .car-services .services__title {
    font-size: 40px;
  }
}
